﻿window.bannerSnippet = {
    log: function (id, status, extra) {
        var elLogging = document.getElementById(id).parentNode.querySelector('.logging');
        console.log('status', status, extra);
        if (elLogging) {
            var now = new Date();
            var time = ('00' + now.getHours()).substr(-2) + ':' + ('00' + now.getMinutes()).substr(-2) + ':' + ('00' + now.getSeconds()).substr(-2);

            var elItem = document.createElement('div');
            elItem.className = 'item';
            elLogging.appendChild(elItem);

            var elTime = document.createElement('div');
            elTime.className = 'time';
            elTime.innerHTML = time;
            elItem.appendChild(elTime);

            var elStatus = document.createElement('div');
            elStatus.className = 'status';
            elStatus.innerHTML = status;
            elItem.appendChild(elStatus);

            if (extra) {
                var elExtra = document.createElement('div');
                elExtra.className = 'extra';
                elExtra.innerHTML = extra;
                elItem.appendChild(elExtra);
            }
        }
    }
};
